@media only screen and (max-width: 1282px) {
    .login-card-image {
        max-height: 80vh;
    }
}

.login-logo-size {
    height: 25vh;
}

.login-card-image {
    max-height: 85vh;
}

@media (max-width: 575.98px) {
    .leadsIconTable_disabledIcon__2Xaiq {
        width: 50px;
        height: 32px;
    }
    
    .leadsIconTable_icon__zoiIn {
        width: 40;
        height: 40px
    }
}

.leadsIconTable_disabledIcon__2Xaiq {
    width: 60px;
    height: 42px;
}

.leadsIconTable_icon__zoiIn {
    width: 50x;
    height: 50px
}
@media (max-width: 767.98px) {
    .leadBoardTable_disabled-button__1j7Ry {
        width: 50%
    }    
}

.leadBoardTable_disabled-button__1j7Ry {
    width: 100%
}
.leads-loader-container {
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(90vh - 70px);
  }
  
  .dots {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .dot1, .dot2, .dot3 {
    background: #fff;
    width: 5px;
    height: 5px;
    border:double;
    border-color:black;
    border-radius: 50%;
    margin: 10px;
  }
  
  .dot1 {
    -webkit-animation: jump 1.6s -0.32s linear infinite;
            animation: jump 1.6s -0.32s linear infinite;
    background: #5290d3;
  }
  .dot2 {
      -webkit-animation: jump 1.6s -0.16s linear infinite;
              animation: jump 1.6s -0.16s linear infinite;
      background: #5290d3;
  }
  .dot3 {
      -webkit-animation: jump 1.6s linear infinite;
              animation: jump 1.6s linear infinite;
      background: #5290d3;
  }
  
  @-webkit-keyframes jump {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(2.0);
      transform: scale(2.0);
    }
  }
  
  @keyframes jump {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(2.0);
      transform: scale(2.0);
    }
  }
  
.leadboard-success-icon {
    color: green;
    font-size: 10rem;
}

.lead-board-success-description {
    border-top: 2px solid #f5f5f5;
    padding-top: 0.5rem;
}

.mask-custom {
  background: hsla(0, 0%, 100%, 0.55);
  display: flex;
  font-size: 18px;
}

.tableFixHead          { overflow: auto; height: 100px; width: 100%; }
.tableFixHead thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: white; }
.tableFixHead tbody th { position: -webkit-sticky; position: sticky; left: 0; }

:root {
    --height-height: calc(90vh - 70px);
    /* cell width has to reserve some space for scrolling. Hence the sum < 100% */
    --cell-width: 14.24%;
  }
  
  .header-fixed {
    width: 100%;
  }
  
  /* Treat all as divs */
  .header-fixed > thead,
  .header-fixed > tbody,
  .header-fixed > thead > tr,
  .header-fixed > tbody > tr,
  .header-fixed > thead > tr > th,
  .header-fixed > tbody > tr > td {
    display: block;
  }
  
  /* Prevent header to wrap */
  .header-fixed > thead > tr > th {
    white-space: nowrap;
    background-color: white;
    text-align: center;
  }
  
  .header-fixed > tbody > tr:after,
  .header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .header-fixed > tbody {
    overflow-y: auto;
    height: calc(90vh - 70px);
    height: var(--height-height);
  }
  
  .header-fixed > tbody > tr > td,
  .header-fixed > thead > tr > th {
    width: 14.24%;
    float: left;
  }
  .header-fixed-mobile {
    width: 100%;
  }
  
  /* Treat all as divs */
  .header-fixed-mobile > thead,
  .header-fixed-mobile > tbody,
  .header-fixed-mobile > thead > tr,
  .header-fixed-mobile > tbody > tr,
  .header-fixed-mobile > thead > tr > th,
  .header-fixed-mobile > tbody > tr > td {
    display: block;
  }
  
  /* Prevent header to wrap */
  .header-fixed-mobile > thead > tr > th {
    white-space: nowrap;
    background-color: white;
  }
  
  .header-fixed-mobile > tbody > tr:after,
  .header-fixed-mobile > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .header-fixed-mobile > tbody {
    overflow-y: auto;
    height: calc(90vh - 70px);
    height: var(--height-height);
  }
  
  .header-fixed-mobile > tbody > tr > td,
  .header-fixed-mobile > thead > tr > th {
    width: 19.8%;
    float: left;
  }

  .blue {
    color: #5290d3;
  }
  
  .lead-title {
    display: inline-block;
  }
  
  .account-status-2-font {
    font-size: 16px;
  }

  .account-status-2 {
    height: 4vh;
  }
  @media (max-width: 767.98px) { 
    .account-status-2 {
    height: auto;
  }
    .account-status-2-font {
      font-size: 12px;
    }
   }

   

.leadboard-success-icon {
    color: green;
    font-size: 10rem;
}

.lead-board-success-description {
    border-top: 2px solid #f5f5f5;
    padding-top: 0.5rem;
}

.col-my-leads {
  height: calc(100vh - 70px) !important;

}

.lead-column {
    width: 22.5%;
    overflow: auto;
}

.tableFixHead          { overflow: auto; height: 100px; width: 100%; }
.tableFixHead thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: white; }
.tableFixHead tbody th { position: -webkit-sticky; position: sticky; left: 0; }

:root {
    --height-height: calc(100vh - 70px);
    /* cell width has to reserve some space for scrolling. Hence the sum < 100% */
  }
  
  .header-fixed {
    width: 100%;
  }
  
  /* Treat all as divs */
  .header-fixed > thead,
  .header-fixed > tbody,
  .header-fixed > thead > tr,
  .header-fixed > tbody > tr,
  .header-fixed > thead > tr > th,
  .header-fixed > tbody > tr > td {
    display: block;
  }
  
  /* Prevent header to wrap */
  .header-fixed > thead > tr > th {
    white-space: nowrap;
    background-color: white;
  }
  
  .header-fixed > tbody > tr:after,
  .header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .header-fixed > tbody {
    overflow-y: auto;
    height: calc(100vh - 70px);
    height: var(--height-height);
  }
  
  .header-fixed > tbody > tr > td,
  .header-fixed > thead > tr > th {
    width: 16.5%;
    float: left;
  }
  .header-fixed-mobile {
    width: 100%;
  }
  
  /* Treat all as divs */
  .header-fixed-mobile > thead,
  .header-fixed-mobile > tbody,
  .header-fixed-mobile > thead > tr,
  .header-fixed-mobile > tbody > tr,
  .header-fixed-mobile > thead > tr > th,
  .header-fixed-mobile > tbody > tr > td {
    display: block;
  }
  
  /* Prevent header to wrap */
  .header-fixed-mobile > thead > tr > th {
    white-space: nowrap;
    background-color: white;
  }
  
  .header-fixed-mobile > tbody > tr:after,
  .header-fixed-mobile > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .header-fixed-mobile > tbody {
    overflow-y: auto;
    height: calc(100vh - 70px);
    height: var(--height-height);
  }
  
  .header-fixed-mobile > tbody > tr > td,
  .header-fixed-mobile > thead > tr > th {
    width: 24.8%;
    float: left;
  }


  .fixed_header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 50vh;
  }


/* Style Definitions */

 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
@media(max-width: 767.98px) {
    .table-manage-billing-responsive {
        display: block; 
        width: 100%; 
        overflow-x: auto;
    }
}
.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 25vmin;
    width: 60vmin;
    pointer-events: none;
  }
  
  @media (max-width: 767.98px) { 
    .SearchBox {
      background-color: rgb(247, 250, 252);
      min-height: 10vh;
  }
    
   }


  .SearchBox {
      background-color: rgb(247, 250, 252);
      min-height: 14vh;
  }
  
  .MapBox {
    min-height: calc(86vh - 70px);
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .MapBox-userType2 {
    min-height: calc(82vh - 70px);
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  

  .tableColumn {
    height: calc(84vh - 70px);
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    
  
  }

  .tableColumn-userType2 {
    height: calc(82vh - 70px);
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    
  
  }
  
  
  .appTable {
    height: calc(90vh - 70px);
    max-width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    overflow-y: scroll;
  }
  
  .navbar {
    height: 70px;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .navbar > li:first-child{
    border-bottom: 1px #c9c9c9 solid;      
    background: #2980b9;        
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .breadcrumb {
    background-color: #ffffff;
  }
  
  body {
    background-color: #E6E8E8;
  }
  
  .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
    }
    .card-registration .select-arrow {
    top: 13px;
    }

    .signout-btn {
      font-size: 2rem;
      cursor: pointer;
    }

.nav-link {
  color: gray;
}
