.leads-loader-container {
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(90vh - 70px);
  }
  
  .dots {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .dot1, .dot2, .dot3 {
    background: #fff;
    width: 5px;
    height: 5px;
    border:double;
    border-color:black;
    border-radius: 50%;
    margin: 10px;
  }
  
  .dot1 {
    animation: jump 1.6s -0.32s linear infinite;
    background: #5290d3;
  }
  .dot2 {
      animation: jump 1.6s -0.16s linear infinite;
      background: #5290d3;
  }
  .dot3 {
      animation: jump 1.6s linear infinite;
      background: #5290d3;
  }
  
  @keyframes jump {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(2.0);
      transform: scale(2.0);
    }
  }
  