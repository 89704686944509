.leadboard-success-icon {
    color: green;
    font-size: 10rem;
}

.lead-board-success-description {
    border-top: 2px solid #f5f5f5;
    padding-top: 0.5rem;
}

.mask-custom {
  background: hsla(0, 0%, 100%, 0.55);
  display: flex;
  font-size: 18px;
}

.tableFixHead          { overflow: auto; height: 100px; width: 100%; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; background-color: white; }
.tableFixHead tbody th { position: sticky; left: 0; }

:root {
    --height-height: calc(90vh - 70px);
    /* cell width has to reserve some space for scrolling. Hence the sum < 100% */
    --cell-width: 14.24%;
  }
  
  .header-fixed {
    width: 100%;
  }
  
  /* Treat all as divs */
  .header-fixed > thead,
  .header-fixed > tbody,
  .header-fixed > thead > tr,
  .header-fixed > tbody > tr,
  .header-fixed > thead > tr > th,
  .header-fixed > tbody > tr > td {
    display: block;
  }
  
  /* Prevent header to wrap */
  .header-fixed > thead > tr > th {
    white-space: nowrap;
    background-color: white;
    text-align: center;
  }
  
  .header-fixed > tbody > tr:after,
  .header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .header-fixed > tbody {
    overflow-y: auto;
    height: var(--height-height);
  }
  
  .header-fixed > tbody > tr > td,
  .header-fixed > thead > tr > th {
    width: 14.24%;
    float: left;
  }
  .header-fixed-mobile {
    width: 100%;
  }
  
  /* Treat all as divs */
  .header-fixed-mobile > thead,
  .header-fixed-mobile > tbody,
  .header-fixed-mobile > thead > tr,
  .header-fixed-mobile > tbody > tr,
  .header-fixed-mobile > thead > tr > th,
  .header-fixed-mobile > tbody > tr > td {
    display: block;
  }
  
  /* Prevent header to wrap */
  .header-fixed-mobile > thead > tr > th {
    white-space: nowrap;
    background-color: white;
  }
  
  .header-fixed-mobile > tbody > tr:after,
  .header-fixed-mobile > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .header-fixed-mobile > tbody {
    overflow-y: auto;
    height: var(--height-height);
  }
  
  .header-fixed-mobile > tbody > tr > td,
  .header-fixed-mobile > thead > tr > th {
    width: 19.8%;
    float: left;
  }

  .blue {
    color: #5290d3;
  }
  
  .lead-title {
    display: inline-block;
  }
  
  .account-status-2-font {
    font-size: 16px;
  }

  .account-status-2 {
    height: 4vh;
  }
  @media (max-width: 767.98px) { 
    .account-status-2 {
    height: auto;
  }
    .account-status-2-font {
      font-size: 12px;
    }
   }

   
