.App {
    text-align: center;
  }
  
  .App-logo {
    height: 25vmin;
    width: 60vmin;
    pointer-events: none;
  }
  
  @media (max-width: 767.98px) { 
    .SearchBox {
      background-color: rgb(247, 250, 252);
      min-height: 10vh;
  }
    
   }


  .SearchBox {
      background-color: rgb(247, 250, 252);
      min-height: 14vh;
  }
  
  .MapBox {
    min-height: calc(86vh - 70px);
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .MapBox-userType2 {
    min-height: calc(82vh - 70px);
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  

  .tableColumn {
    height: calc(84vh - 70px);
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    
  
  }

  .tableColumn-userType2 {
    height: calc(82vh - 70px);
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    
  
  }
  
  
  .appTable {
    height: calc(90vh - 70px);
    max-width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    overflow-y: scroll;
  }
  
  .navbar {
    height: 70px;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .navbar > li:first-child{
    border-bottom: 1px #c9c9c9 solid;      
    background: #2980b9;        
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .breadcrumb {
    background-color: #ffffff;
  }
  
  body {
    background-color: #E6E8E8;
  }
  
  .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
    }
    .card-registration .select-arrow {
    top: 13px;
    }

    .signout-btn {
      font-size: 2rem;
      cursor: pointer;
    }

.nav-link {
  color: gray;
}