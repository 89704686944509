@media (max-width: 575.98px) {
    .disabledIcon {
        width: 50px;
        height: 32px;
    }
    
    .icon {
        width: 40;
        height: 40px
    }
}

.disabledIcon {
    width: 60px;
    height: 42px;
}

.icon {
    width: 50x;
    height: 50px
}